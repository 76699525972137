<template>
  <div class="changetel">
    <!-- 头部 -->
    <van-nav-bar
      title="更改手机号"
      left-arrow
      @click-left="onClickLeft"
      class="dark_bg"
    />
    <div class='phone-main'>
      <!-- <van-field
        v-model="phone"
        label="手机号:"
        placeholder="请输入新手机号"
        class="dark_shop_bg"
      />
      
      <van-field
        v-model="sms"
        center
        clearable
        label="短信验证码:"
        placeholder="请输入短信验证码"
        class="dark_shop_bg"
      >
        <span
          slot="button"
          class="getsms"
          v-if="showtime === null"
          @click.prevent="getCode($event)"
          v-preventReClick="3000"
          >发送验证码</span
        >
        <em slot="button" v-else>{{ showtime }}</em>
      </van-field> -->
      <h3 class='phone-name'>原手机号码</h3>
        
        <input placeholder='请输入手机号' v-model="phone"   type="text" class='phone-input'/>
      <h3 class='phone-name phone-name-top'>绑定新手机号码</h3>
        <input placeholder='请输入手机号' @input='handleInput' v-model="newPhone" type='tel' class='phone-input'/>

        <div class='verify'>
        
          <input type='tel' v-model="sms" placeholder='请输入短信验证码' class='verify-input'/>
          <van-button class='verify-btn' :disabled='disabled'  @click.prevent="getCode($event)"
          v-preventReClick="3000" type="default" v-if="showtime === null">获取验证码</van-button>
           <!-- <em class='verify-text'>{{ showtime }}</em> -->
            <van-button v-else class='verify-btn' :disabled='true'  type="default">{{ showtime }}</van-button>
        </div>

    </div>
    <p class='bottom-btn'>
      <Btn class='save-btn' @click.native="sureEdit">确定</Btn>
    </p>
  </div>
</template>

<script>
import { NavBar, Field } from "vant";
import Btn from "../../common/btn";
let accessToken = localStorage.getItem("token");
export default {
  data() {
    return {
      phone: null,
      newPhone:null,
      sms: null,
      showtime: null,
      disabled:true
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    Btn,
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后再获取`;
    },
    // 倒计时 60秒 不需要很精准
    countDown(times) {
      const self = this;
      // 时间间隔 1秒
      const interval = 1000;
      let count = 0;
      self.timeCounter = setTimeout(countDownStart, interval);
      // 定时器中的方法
      function countDownStart() {
        if (self.timeCounter == null) {
          return false;
        }
        count++;
        self.countDownText(times - count + 1);
        if (count > times) {
          self.showlogin = true;
          clearTimeout(self.timeCounter);
          self.showtime = null;
        } else {
          self.timeCounter = setTimeout(countDownStart, interval);
        }
      }
    },
    // 获取验证码
    getCode(event) {
      // 点击太过频繁
      if (event.target.disabled) {
        return false;
      }
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.phone)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        return false;
      } else {
        this.$api.login
          .yanCodeAddress({
            phone: this.newPhone,
            _s: 5,
          })
          .then((res) => {
            if (res.errno == 200) {
              // 调用倒计时
              this.countDown(60);
              // 测试验证码
              this.sms = res.data.code;
            }
          });
      }
    },
     handleInput() {
      console.log("变色");
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.phone)) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // 确定修改
    sureEdit() {
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.phone)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        return false;
      } else {
        this.$api.login
          .editPhone({
            userId: localStorage.customerId,
            beforePhone: this.phone,
            mobileCode: this.sms,
            phone:this.newPhone
          })
          .then((res) => {
            if (res.errno == 200) {
               localStorage.setItem(
                  "mobile",
                  res.data.phone
                );
              this.$toast({
                message: "修改成功",
                onClose: () => {
                  this.$router.replace("/my");
                },
              });
            } else {
              this.$toast(res.errmsg);
            }
          });
      }
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../less/element.less";
@red: #c83f3f;
.changetel {
  width: 100%;
  box-sizing: border-box;
  .van-nav-bar {
    // margin: 0 0 0.3rem;
    .van-icon {
      color: #ccc;
    }
    .van-nav-bar__title {
      color: @red;
    }
  }
  .van-cell {
    padding: 0.4rem;
    // margin-left: 5%;
    // margin: 0 0.2rem;
    .getsms {
      color: @red;
    }
  }
  & > p {
    text-align: center;
    // margin: 0.5rem 0 0;
    .btn {
      .btnmin(
        @padding:0,
        @radius:0.1rem,
        @size:0.36rem,
        @bgcolor:@ordinary_btn_color,
        @color:#fff,
      );
    }
  }
}
// .dark_shop_bg {
//   // width: 90%;
// }
.bottom-btn{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.3rem;
  background: #FFFFFF;
  box-shadow: 0px 0px .2rem 0px rgba(7, 63, 53, 0.1);
  .save-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 6.3rem;
    height: .9rem;
    // background: linear-gradient(0deg, #EE3A3A, #F46161);
    background: @ordinary_btn_color;
    border-radius: .1rem;

    font-size: .36rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: .9rem !important;
  }
}
.phone-main{
  padding: .5rem .3rem;
}
.phone-name{
    margin-bottom:-.14rem;
    font-size: .32rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: .49rem;
}
.phone-input{
  padding: .3rem;
  width: 100%;
  height: .9rem;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: .1rem;
  box-sizing: border-box;
  
  font-size: .28rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: .4rem;

   &::-webkit-input-placeholder {
         font-size: .28rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #AEAEAE;
        line-height: .4rem;
    }

}
.phone-name-top{
  margin-top: .58rem;
}
.verify{
  display: flex;
  justify-content: space-between;
  margin-top: .3rem;

    .verify-input{
      padding: .3rem;
      box-sizing: border-box;

      width: 4.2rem;
    height: .9rem;
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: .1rem;

    font-size: .28rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: .4rem;
     &::-webkit-input-placeholder {
         font-size: .28rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #AEAEAE;
        line-height: .4rem;
    }
    }
    .verify-btn{
      margin-left: .3rem;
    width: 2.4rem;
    height: .9rem;
    // background: #FFFFFF;
    // border: 1px solid #EFEFEF;
    // color: #AEAEAE;
    font-size: .28rem;
    background-color: @ordinary_btn_color;
    color: #fff;
    border-radius: .1rem;

    font-family: PingFang SC;
    font-weight: 500;
    line-height: .4rem;
     
    }
}

</style>
